import { useSelector } from 'react-redux';

export default function ValueCard({ title, description }) {
  const { language } = useSelector((state) => state.lang);
  const arLang = language === 'ar' ? true : false;
  return (
    <div className="about__our-core__value-card text--white ">
      <h3 className="fw--700">
        {title}
        {/* {arLang ? (
          <>
            {title.startsWith('ال') ? (
              <>
                <span>{title.slice(0, 3)}</span>
                <span>{title.slice(3)}</span>
              </>
            ) : (
              <>
                <span>{title.slice(0, 2)}</span>
                <span>{title.slice(2)}</span>
              </>
            )}
          </>
        ) : (
          <>
            <span>{title.slice(0, 2)}</span>
            <span>{title.slice(2)}</span>
          </>
        )} */}
      </h3>
      <p className="">{description}</p>
    </div>
  );
}
