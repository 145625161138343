import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { closeTermsModal } from './Store/uiSlice';
import 'react-toastify/dist/ReactToastify.css';

// components
import AppRoutes from './Router/AppRoutes';

function App() {
  const { language } = useSelector((state) => state.lang);
  const { openTerms } = useSelector((state) => state.ui);
  const { i18n } = useTranslation();
  const [search] = useSearchParams();
  const dispatch = useDispatch();

  const openModal = search.has('openTermsAndConditions');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  useEffect(() => {
    if (!openModal) {
      dispatch(closeTermsModal());
    }
  }, []);

  return (
    <div className="App d-flex flex-column">
      <Helmet>
        <html lang={language} dir={language === 'ar' ? 'rtl' : 'ltr'}></html>
      </Helmet>
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
