import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import imageEn from '../Assets/Images/404-en.png';
import imageAr from '../Assets/Images/404-ar.png';
import imageBs from '../Assets/Images/404-bs.png';
import i18next from 'i18next';

const Page404 = () => {
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();

  let src = imageEn;
  if (language === 'ar') src = imageAr;
  if (language === 'hr') src = imageBs;

  return (
    <section
      dir={i18next.dir()}
      className="page-404 bg--main-stars flex-fill d-flex align-items-center justify-content-center"
    >
      <div>
        <img
          src={src}
          alt=""
          className="page-404__main-image img-fluid d-block mx-auto"
        />
        <h1 className="page-404__main-heading text--green text-center fw--500 fsize--2">
          {t('page404.heading')}
        </h1>
        <p className="page-404__main-paragraph text--white text-center fw--500 fsize--6 mx-auto">
          {t('page404.paragraph')}
        </p>
        <Link
          to="/home"
          className="page-404__main-link bg--main-green text--white fw-bold fsize--4 d-block mx-auto text-center"
        >
          {t('page404.link')}
        </Link>
      </div>
    </section>
  );
};

export default Page404;
