import { useTranslation } from 'react-i18next';

const SecondSection = () => {
  const { t } = useTranslation();

  return (
    <div className="about__second-section d-flex flex-column">
      <h2 className="text--white what-we-do">
        {t('about.secondSection.whatWeDo')}
      </h2>
      <p className="text--white paragraph mb-0">{t('about.secondSection.1')}</p>
    </div>
  );
};

export default SecondSection;
