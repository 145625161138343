import { useEffect, useState } from 'react';
import { getCurrentLang, getStores } from '../APIs/Network';
import empty from '../Assets/Images/empty.gif';
import { toastError } from '../Helpers/Toasters';
import { useTranslation } from 'react-i18next';
import StoreCard from '../Components/Stores/StoreCard';
import Loading from '../Assets/Images/loading-state.gif';

export default function Stores() {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const language = getCurrentLang();
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  useEffect(() => {
    setLoading(true);
    getStores(
      (res) => {
        setStores(res.data);
        setLoading(false);
      },
      (err) => {
        toastError(err.message || 'An error occurred!');
        setLoading(false);
      }
    );
  }, [language]);

  return (
    <section className="stores" dir={dir}>
      {loading && (
        <div className="stores-loading">
          <img src={Loading} alt="loading" />
        </div>
      )}

      {!loading &&
        (stores && stores.length > 0 ? (
          stores.map((store) => <StoreCard key={store.id} store={store} />)
        ) : (
          <div className="no-data">
            <img src={empty} alt="empty" />
            <p>{t('stores.noActiveStores')}</p>
          </div>
        ))}
    </section>
  );
}
