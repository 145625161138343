import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// assets
import videoOverlay from '../../Assets/Images/About/video-overlay.png';
import videoIcon from '../../Assets/Icons/About/video-play.svg';

const FourthSection = () => {
  const [hideOverlay, setHideOverlay] = useState(false);
  const [removeOverlay, setRemoveOverlay] = useState(false);
  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  const { t } = useTranslation();
  const videoRef = useRef(null);
  const videoLink =
    'https://api.thegreenmatter.com/StaticFiles/Videos/The%20Greenmatter.mp4';
  let timerId;

  const handleStartVideo = () => {
    setHideOverlay(true);
    timerId = setTimeout(() => setRemoveOverlay(true), 500);
    videoRef.current.play();
  };

  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  return (
    <div className="about__video-section" dir={dir}>
      {/* <div className="about__video-section__holder row mx-0 align-items-center"> */}
      <div className="about__video-section__holder">
        {/* <div className="about__video-section__holder__text-holder d-flex flex-column col-xl-6 px-0"> */}
        <div className="about__video-section__holder__text-holder d-flex flex-column">
          <h2 className={`fw--700 text--green  mb-0`}>
            {t('about.scraption')}
          </h2>
          <p className={`fw--400  text--white mb-0`}>
            {t('about.videoSection.description')}
          </p>
        </div>
        {/* <div
          className={`about__video-section__holder__video-holder col-xl-6 px-0 ${
            language === "en" ? "ps-xl-3" : "pe-xl-3"
          }`}
        >
          <div className="position-relative h-100">
            <video className="d-block h-100 w-100" ref={videoRef} controls>
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!removeOverlay ? (
              <div
                className={`about__video-section__holder__video-holder__video-overlay position-absolute w-100 h-100 top-0 start-0 ${
                  hideOverlay ? "hide" : ""
                }`}
              >
                <img
                  src={videoOverlay}
                  alt=""
                  className="w-100 h-100 object-fit-cover"
                />
                <button
                  type="button"
                  onClick={handleStartVideo}
                  className="d-block bg-transparent position-relative w-100 h-100 position-absolute top-0 start-0 outline-0 border-0 d-flex justify-content-center align-items-center"
                >
                  <img src={videoIcon} alt="" />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FourthSection;
