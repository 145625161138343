const HomeIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5594 14.356C31.5584 14.3553 31.5577 14.3543 31.5569 14.3535L18.5034 1.30054C17.947 0.743897 17.2073 0.4375 16.4204 0.4375C15.6335 0.4375 14.8938 0.743897 14.3372 1.30054L1.2905 14.347C1.28611 14.3513 1.28147 14.356 1.27732 14.3604C0.134737 15.5096 0.13669 17.3741 1.28293 18.5203C1.80662 19.0442 2.49802 19.3475 3.23753 19.3794C3.2678 19.3824 3.29807 19.3838 3.32859 19.3838H3.84861V28.9898C3.84861 30.8909 5.39549 32.4376 7.29662 32.4376H12.4036C12.9214 32.4376 13.3411 32.0176 13.3411 31.5001V23.9688C13.3411 23.1014 14.0469 22.3958 14.9143 22.3958H17.9265C18.794 22.3958 19.4995 23.1014 19.4995 23.9688V31.5001C19.4995 32.0176 19.9192 32.4376 20.437 32.4376H25.544C27.4453 32.4376 28.992 30.8909 28.992 28.9898V19.3838H29.4744C30.261 19.3838 31.0008 19.0774 31.5577 18.5205C32.7051 17.3726 32.7056 15.5047 31.5594 14.356ZM30.2317 17.1949C30.0293 17.3973 29.7603 17.5088 29.4744 17.5088H28.0545C27.5366 17.5088 27.117 17.9285 27.117 18.4463V28.9898C27.117 29.857 26.4114 30.5626 25.544 30.5626H21.3745V23.9688C21.3745 22.0677 19.8279 20.5208 17.9265 20.5208H14.9143C13.0129 20.5208 11.4661 22.0677 11.4661 23.9688V30.5626H7.29662C6.42943 30.5626 5.72362 29.857 5.72362 28.9898V18.4463C5.72362 17.9285 5.30394 17.5088 4.78611 17.5088H3.3906C3.37595 17.5078 3.36155 17.5071 3.34666 17.5069C3.06736 17.502 2.8054 17.3911 2.60911 17.1946C2.19163 16.7771 2.19163 16.0977 2.60911 15.68C2.60935 15.68 2.60935 15.6797 2.6096 15.6795L2.61033 15.6787L15.6633 2.62623C15.8655 2.42383 16.1343 2.3125 16.4204 2.3125C16.7063 2.3125 16.9751 2.42383 17.1775 2.62623L30.2276 15.6761C30.2295 15.678 30.2317 15.68 30.2337 15.6819C30.649 16.1001 30.6482 16.7781 30.2317 17.1949Z"
        fill="white"
      />
    </svg>
  );
};

export default HomeIcon;
