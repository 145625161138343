const SupplyPricesIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5001 31C8.4919 31 2 24.5082 2 16.5C2 8.49191 8.4919 2.00003 16.5001 2.00003C24.5081 2.00003 31.0001 8.49191 31.0001 16.5C31.0001 24.5082 24.5081 31 16.5001 31ZM11.425 19.4V22.3H15.0501V25.2H17.9501V22.3H19.4001C21.4021 22.3 23.0251 20.6772 23.0251 18.675C23.0251 16.673 21.4021 15.05 19.4001 15.05H13.6001C13.1996 15.05 12.875 14.7255 12.875 14.325C12.875 13.9246 13.1996 13.6 13.6001 13.6H21.5751V10.7001H17.9501V7.80005H15.0501V10.7001H13.6001C11.598 10.7001 9.97502 12.323 9.97502 14.325C9.97502 16.3272 11.598 17.95 13.6001 17.95H19.4001C19.8004 17.95 20.1251 18.2747 20.1251 18.675C20.1251 19.0755 19.8004 19.4 19.4001 19.4H11.425Z"
        fill="white"
      />
    </svg>
  );
};

export default SupplyPricesIcon;
