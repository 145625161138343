import { NavLink } from 'react-router-dom';

const NavbarLink = ({ title, icon, path }) => {
  return (
    <NavLink
      className="nav-link d-inline-flex flex-lg-column align-items-center py-0"
      to={`/${path}`}
    >
      {icon}
      <span className="nav-link__title text-center fsize--7 text-capitalize text--white">
        {title}
      </span>
    </NavLink>
  );
};

export default NavbarLink;
