import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSupplyTypes } from '../APIs/Network';
import { toastError } from '../Helpers/Toasters';

// assets
import loadingIcon from '../Assets/Images/loading-state.gif';

// components
import DataHolder from '../Components/SupplyPrices/DataHolder';

const SupplyPrices = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getSupplyTypes(
      (res) => {
        if (res.success) {
          setData(res.data);
        } else {
          toastError(res.message);
        }
        setLoading(false);
      },
      (res) => {
        setLoading(false);
        toastError(res?.data?.toastError);
      }
    );
  }, [language]);

  return (
    <section className="supply-prices flex-fill bg--main-stars d-flex flex-column">
      {loading ? (
        <div className="supply-prices__loading-holder flex-fill d-flex justify-content-center align-items-center">
          <img src={loadingIcon} alt="loading" className="d-block img-fluid" />
        </div>
      ) : (
        <div className="supply-prices__holder d-flex flex-column">
          <p className="text-center text--white fsize--5 px-5">{t('supplyPrices.note')}</p>
          <div className="row m-0">
            <div className="col-4 px-md-3">
              <DataHolder data={t('supplyPrices.material')} type="title" />
            </div>
            <div className="col-4 px-md-3">
              <DataHolder data={t('supplyPrices.cashPrice')} type="title" />
            </div>
            <div className="col-4 px-md-3">
              <DataHolder data={t('supplyPrices.points')} type="title" />
            </div>
          </div>
          {data?.map((ele) => (
            <div key={ele.id} className="row m-0">
              <div className="col-4 px-md-3">
                <DataHolder data={ele.material} type="value" />
              </div>
              <div className="col-4 px-md-3">
                <DataHolder data={ele.cashPrice} type="value" />
              </div>
              <div className="col-4 px-md-3">
                <DataHolder data={ele.points} type="value" />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default SupplyPrices;
