import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ThirdSection = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <div
      dir="ltr"
      className={`about__join-section ${
        language === 'ar' ? 'about__join-section--rotate' : ''
      }`}
    >
      <div
        dir={dir}
        className="about__join-section__text-holder d-flex flex-column justify-content-center"
      >
        <h2 className="text--white mb-0">
          <span className="d-block text--white">
            {t('about.joinSection.joinUs')}
          </span>
          <span className="d-block text--green">{t('about.scraption')}</span>
        </h2>
        <p className="text--white mb-0 ">
          {t('about.joinSection.description')}
        </p>
      </div>
    </div>
  );
};

export default ThirdSection;
