import { Button, Typography } from 'antd';
import CustomOutlinedInput from '../Components/Inputs/OutlinedInput';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { contactUs } from '../APIs/Network';
import { toastError, toastSuccess } from '../Helpers/Toasters';
import Wrapper from '../Components/Wrapper/Wrapper';

const ContactUs = () => {
  const [countryCode, setCountryCode] = useState('+20');
  const [inquiry, setInquiry] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  // State for each field and errors
  const [form, setForm] = useState({
    name: '',
    companyName: '',
    phone: '',
    email: '',
    inquiryType: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        if (!value) return t('privacyPolicy.contactUs.validation.name');
        break;
      // case 'companyName':
      //   if (!value) return t('privacyPolicy.contactUs.validation.name');
      //   break;
      case 'phone':
        // if (countryCode === '+20' && !/^\d{10}$/.test(value)) {
        if (countryCode === '+20') {
          if (!value.length) {
            return t('privacyPolicy.contactUs.validation.phone');
            // } else if (!/^0?1\d{9}$/.test(value)) {
          } else if (!/^(0?1(0|1|2|5))\d{8}$/.test(value)) {
            return t('privacyPolicy.contactUs.validation.phoneNotValid');
          }
        }
        // if (countryCode === '+387' && !/^\d{8}$/.test(value)) {
        if (countryCode === '+387') {
          if (!value.length) {
            return t('privacyPolicy.contactUs.validation.phone');
          } else if (!/^6\d{7}$/.test(value)) {
            return t('privacyPolicy.contactUs.validation.phoneNotValid');
          }
        }
        break;
      case 'email':
        // if (!/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        if (!/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          return t('privacyPolicy.contactUs.validation.email');
        }
        break;
      case 'inquiryType':
        if (
          !value ||
          value === t('privacyPolicy.contactUs.placeHolders.inquirey')
        )
          return t('privacyPolicy.contactUs.validation.inquiry');
        break;
      case 'message':
        if (!value.length)
          return t('privacyPolicy.contactUs.validation.message');
        break;
      default:
        break;
    }
    return null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'inquiryType') {
      setInquiry(value);
    }

    // localStorage.removeItem('inquiryType');
    setForm({ ...form, [name]: value });
    // Validate on change
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const handleCountryCodeChange = (e) => {
    const { value } = e.target;
    setCountryCode(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: null,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setDisabled(true);

    const validationErrors = {};

    Object.keys(form).forEach((field) => {
      const error = validateField(field, form[field]);
      if (error) validationErrors[field] = error;
    });

    if (Object.keys(validationErrors).length === 0) {
      console.log(form);
      const contactUsData = {
        ...form,
        phone: form.phone.includes(countryCode)
          ? form.phone
          : countryCode + form.phone,
      };
      console.log('🚀 ~ handleSubmit ~ contactUsData:', contactUsData);
      setLoading(true);
      contactUs(
        contactUsData,
        (res) => {
          if (res.success) {
            toastSuccess(res?.message);
            setForm({
              name: '',
              companyName: '',
              phone: '',
              email: '',
              inquiryType: '',
              message: '',
            });
            setLoading(false);
          } else {
            toastError(res?.message);
            setLoading(false);
          }

          setDisabled(false);
        },
        (err) => {
          toastError(err?.message);
          setDisabled(false);
        }
      );
    } else {
      setErrors(validationErrors);
      setDisabled(false);
    }
  };

  return (
    // <Wrapper style={{ marginBlock: '116px 85px' }}>
    <div className="contact-us__section">
      <form className="contact-us" dir={dir}>
        <Typography.Title
          level={3}
          className="contact-us__title"
          // style={{ color: '#fff', marginBottom: '20px', fontWeight: '700' }}
        >
          {t('privacyPolicy.contactUs.title')}
        </Typography.Title>

        <div className="contact-us__field-group">
          <CustomOutlinedInput
            label={t('privacyPolicy.contactUs.fields.name')}
            name="name"
            value={form.name}
            error={errors.name}
            onChange={handleChange}
            className="contact-us__field-group__input"
          />
          <CustomOutlinedInput
            label={t('privacyPolicy.contactUs.fields.companyName')}
            name="companyName"
            value={form.companyName}
            error={errors.companyName}
            onChange={handleChange}
            className="contact-us__field-group__input"
          />
        </div>

        <div className="contact-us__field-group">
          <CustomOutlinedInput
            label={t('privacyPolicy.contactUs.fields.mobile')}
            name="phone"
            value={form.phone}
            error={errors.phone}
            onChange={handleChange}
            className="contact-us__field-group__input"
            component="phone"
            onCodeChange={handleCountryCodeChange}
          />
          {/* Country Code Dropdown and Phone Number Field */}
          <CustomOutlinedInput
            label={t('privacyPolicy.contactUs.fields.inquirey')}
            name="inquiryType"
            value={form.inquiryType}
            error={errors.inquiryType}
            onChange={handleChange}
            className="contact-us__field-group__input"
            component="select"
            inquiryType={inquiry}
          />
        </div>

        <CustomOutlinedInput
          label={t('privacyPolicy.contactUs.fields.email')}
          name="email"
          value={form.email}
          error={errors.email}
          onChange={handleChange}
          className="contact-us__input"
        />
        <CustomOutlinedInput
          label={t('privacyPolicy.contactUs.fields.message')}
          name="message"
          value={form.message}
          error={errors.message}
          onChange={handleChange}
          component="textarea"
          className="contact-us__input"
          placeholder={t('privacyPolicy.contactUs.placeHolders.message')}
        />

        <Button
          onClick={handleSubmit}
          disabled={disabled}
          className="submit-btn"
          loading={loading}
        >
          {t('privacyPolicy.contactUs.submit')}
        </Button>
      </form>
    </div>
    // </Wrapper>
  );
};

export default ContactUs;
