// components
import { useSearchParams } from 'react-router-dom';
import FirstSection from '../Components/Home/FirstSection';
import SecondSection from '../Components/Home/SecondSection';
import ThirdSection from '../Components/Home/ThirdSection';
import { useEffect } from 'react';
import store from '../Store';
import { openTermsModal } from '../Store/uiSlice';

const Home = () => {
  const [searchParams] = useSearchParams();
  const showTermsCondition = searchParams.has('openTermsAndConditions');

  useEffect(() => {
    if (showTermsCondition) {
      store.dispatch(openTermsModal());
    }
  }, [showTermsCondition]);

  return (
    <section className="home flex-fill">
      <FirstSection />
      <div className="bg--main-stars">
        <SecondSection />
        <ThirdSection />
      </div>
    </section>
  );
};

export default Home;
