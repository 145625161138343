import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// assets
import mobilesImageEn from '../../Assets/Images/Home/third-section--en.png';
import mobilesImageAr from '../../Assets/Images/Home/third-section--ar.png';
import mobilesImageBs from '../../Assets/Images/Home/third-section--bs.png';
import googleStore from '../../Assets/Images/google-play.png';
import appleStore from '../../Assets/Images/app-store.png';
// import appleStore from "../../Assets/Images/app-store.png";

const ThirdSection = () => {
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();

  const dir = language === 'ar' ? 'rtl' : 'ltr';

  let src = mobilesImageAr;

  if (language === 'en') {
    src = mobilesImageEn;
  } else if (language === 'hr') {
    src = mobilesImageBs;
  }

  return (
    <div
      dir={dir}
      className="home__third-section d-flex flex-column flex-xl-row justify-content-center align-items-center"
    >
      <img
        src={src}
        alt=""
        className="d-block home__third-section__hero img-fluid"
      />
      <div className="home__third-section__text-holder d-flex flex-column">
        <h2 className="text--green fw-bold fsize--2 text-center mb-0">
          {t('home.thirdSection.title')}
        </h2>
        <p className="text--white fw--500 fsize--5 text-center mb-0">
          {t('home.thirdSection.getApplication')}
        </p>
        <div className="home__third-section__text-holder__links d-flex flex-column align-items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.nationtech.bigscrappers"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googleStore} alt="" className="d-block img-fluid" />
          </a>
          <a
            href="https://apps.apple.com/eg/app/big-scrappers/id6557068840"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appleStore} alt="" className="d-block img-fluid" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
