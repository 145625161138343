import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { setLanguage } from '../Store/Language';

// assets
import googleStore from '../Assets/Images/google-play.png';
import appleStore from '../Assets/Images/app-store.png';
import logoEn from '../Assets/Images/logo-en.png';
import logoAr from '../Assets/Images/logo-ar.png';

// components
import LanguageIcon from './LanguageBtn/LanguageIcon';

const LanguageBtn = () => {
  const [toggle, setToggle] = useState(false);
  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const dispatch = useDispatch();

  const handleLanguageChange = (value) => {
    dispatch(setLanguage(value));
  };

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'عربي', value: 'ar' },
    { label: 'Bosnian', value: 'hr' },
  ];

  return (
    <button
      onClick={() => setToggle((pre) => !pre)}
      type="button"
      className="languages-btn languages-btn--footer"
    >
      <LanguageIcon />
      <p style={{ color: '#fff' }}>
        {language === 'ar'
          ? 'عربي'
          : language === 'hr'
          ? 'BS'
          : language.toUpperCase()}
      </p>
      {toggle && (
        <ul className="languages-list" dir={dir} style={{ right: '-20%' }}>
          {languages.map((lang) => (
            <li
              onClick={() => handleLanguageChange(lang.value)}
              key={lang.value}
              style={{
                color: language === lang.value ? '#60EC2F' : '#000',
                listStyle: 'none',
              }}
            >
              <span>{lang.label}</span>
              <span>{language === lang.value && <CheckOutlined />}</span>
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};

const Footer = ({ onOpenTermsModal }) => {
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();
  const location = useLocation();
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const navLinks = [
    { key: 1, name: t('navbar.home'), link: '/home' },
    { key: 2, name: t('navbar.aboutUs'), link: '/about-us' },
    { key: 3, name: t('navbar.terms'), link: '' },
    { key: 4, name: t('navbar.privacyPolicy'), link: '/privacy-policy' },
    { key: 5, name: t('navbar.contactUs'), link: '/contact-us' },
  ];

  const handleChangingPath = (path) => {
    if (location.pathname === path) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <footer dir={dir} className="footer">
      <div className="footer__holder mx-auto">
        <ul className="footer__holder__nav-links d-flex flex-wrap align-items-center justify-content-center list-unstyled">
          {navLinks.map((ele) => (
            <li
              key={ele.key}
              className={`${language === 'ar' ? 'lang--ar' : 'lang--en'}`}
            >
              {ele.link ? (
                <Link
                  to={ele.link}
                  onClick={() => handleChangingPath(ele.link)}
                  className="text--white fsize--6 text-capitalize fw-bold"
                >
                  {ele.name}
                </Link>
              ) : (
                <button
                  type="button"
                  onClick={onOpenTermsModal}
                  className="bg-transparent border-0 outline-none shadow-none text--white fsize--6 text-capitalize fw-bold"
                >
                  {ele.name}
                </button>
              )}
            </li>
          ))}
          <li>
            <LanguageBtn />
          </li>
        </ul>
        <div dir="ltr" className="row m-0 flex-column-reverse flex-xl-row">
          <div className="col-xl-6 px-0 footer__holder__copy-rights-col d-flex justify-content-center justify-content-xl-start">
            <div className="d-flex flex-column">
              <img
                src={language === 'ar' ? logoAr : logoEn}
                alt="Big Scrappers logo"
                className="img-fluid d-block mx-auto"
              />
              <p
                className="text--white text-center fsize--6 fw-bold mb-0"
                style={{ width: language === 'hr' ? 'max-content' : '' }}
              >
                {t('footer.bigScrappersWebsite')} <br /> 2024
              </p>
            </div>
          </div>
          <div className="footer__holder__get-app-links-col col-xl-6 px-0 d-flex justify-content-center justify-content-xl-end">
            <div className="footer__holder__get-app-links-col__holder d-flex flex-column align-items-center">
              <p className="text--white fsize--7 fw-bold text-center mb-0">
                {t('home.thirdSection.getApplication')}
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.nationtech.bigscrappers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googleStore} alt="" className="d-block img-fluid" />
              </a>
              <a
                href="https://apps.apple.com/eg/app/big-scrappers/id6557068840"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appleStore} alt="" className="d-block img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
