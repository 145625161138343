const AboutIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2314 6.12933C25.59 4.48109 23.5791 3.2482 21.3657 2.53304C19.1523 1.81787 16.8002 1.64108 14.5047 2.01733C11.5442 2.51462 8.80547 3.90227 6.65351 5.99539C4.50155 8.08851 3.03853 10.7877 2.45938 13.7333C1.87588 16.7744 2.27368 19.9223 3.59538 22.7227L2.78071 26.7667C2.67692 27.2895 2.70362 27.8299 2.85846 28.3399C3.01329 28.85 3.29148 29.314 3.6684 29.691C4.04533 30.0679 4.50936 30.3461 5.01943 30.5009C5.5295 30.6558 6.06987 30.6825 6.59272 30.5787L10.6314 29.7707C12.5785 30.6913 14.7056 31.1681 16.8594 31.1667C17.7881 31.1663 18.7147 31.0797 19.6274 30.908C22.5743 30.328 25.2744 28.8635 27.3679 26.7098C29.4613 24.5561 30.8485 21.8155 31.3447 18.8533C31.7211 16.5582 31.5443 14.2063 30.8288 11.9932C30.1134 9.78016 28.8801 7.76983 27.2314 6.12933ZM28.7074 18.4427C28.4038 20.3063 27.6642 22.0719 26.5488 23.5955C25.4335 25.1191 23.9739 26.3576 22.289 27.2101C20.6042 28.0626 18.7418 28.5049 16.8536 28.5011C14.9653 28.4973 13.1047 28.0473 11.4234 27.188C11.2362 27.0916 11.0286 27.0413 10.818 27.0413C10.7285 27.0414 10.6392 27.0503 10.5514 27.068L6.07138 27.964C5.97812 27.9825 5.88175 27.9776 5.79079 27.95C5.69983 27.9224 5.61708 27.8727 5.54986 27.8055C5.48264 27.7383 5.43301 27.6555 5.40537 27.5646C5.37773 27.4736 5.37292 27.3773 5.39138 27.284L6.28738 22.8027C6.34664 22.5085 6.30433 22.203 6.16738 21.936C5.30862 20.2543 4.85927 18.3935 4.85595 16.5052C4.85262 14.6169 5.29541 12.7545 6.14823 11.0698C7.00106 9.38507 8.23981 7.92563 9.76357 6.81036C11.2873 5.6951 13.053 4.95556 14.9167 4.652C16.797 4.34589 18.723 4.49122 20.5361 5.07599C22.3491 5.66076 23.9971 6.66822 25.3441 8.01526C26.6912 9.36229 27.6986 11.0103 28.2834 12.8233C28.8682 14.6363 29.0135 16.5624 28.7074 18.4427Z"
        fill="white"
      />
      <path
        d="M18.1927 20.5V15.1667C18.1927 14.8131 18.0523 14.4739 17.8022 14.2239C17.5522 13.9738 17.213 13.8333 16.8594 13.8333H15.5261C15.1725 13.8333 14.8333 13.9738 14.5833 14.2239C14.3332 14.4739 14.1927 14.8131 14.1927 15.1667C14.1927 15.5203 14.3332 15.8594 14.5833 16.1095C14.8333 16.3595 15.1725 16.5 15.5261 16.5V21.8333C15.5261 22.187 15.6666 22.5261 15.9166 22.7762C16.1667 23.0262 16.5058 23.1667 16.8594 23.1667H18.1927C18.5464 23.1667 18.8855 23.0262 19.1356 22.7762C19.3856 22.5261 19.5261 22.187 19.5261 21.8333C19.5261 21.4797 19.3856 21.1406 19.1356 20.8905C18.8855 20.6405 18.5464 20.5 18.1927 20.5Z"
        fill="white"
      />
      <path
        d="M16.8593 12.5C17.5957 12.5 18.1927 11.9031 18.1927 11.1667C18.1927 10.4303 17.5957 9.83334 16.8593 9.83334C16.123 9.83334 15.526 10.4303 15.526 11.1667C15.526 11.9031 16.123 12.5 16.8593 12.5Z"
        fill="white"
      />
    </svg>

    // <svg
    //   width="40"
    //   height="40"
    //   viewBox="0 0 40 40"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="nav-link__icon"
    // >
    //   <path d="M32.965 7.03668C30.9133 4.97638 28.3997 3.43526 25.633 2.54131C22.8662 1.64736 19.926 1.42636 17.0567 1.89668C13.356 2.51828 9.93265 4.25285 7.2427 6.86925C4.55275 9.48566 2.72397 12.8597 2.00004 16.5417C1.27066 20.3431 1.76791 24.2779 3.42004 27.7783L2.40171 32.8333C2.27196 33.4869 2.30534 34.1624 2.49888 34.8C2.69242 35.4375 3.04016 36.0176 3.51132 36.4887C3.98247 36.9599 4.56251 37.3076 5.2001 37.5012C5.83768 37.6947 6.51315 37.7281 7.16671 37.5983L12.215 36.5883C14.6489 37.7391 17.3079 38.3351 20 38.3333C21.1609 38.3329 22.3192 38.2246 23.46 38.01C27.1437 37.285 30.5189 35.4544 33.1356 32.7623C35.7524 30.0702 37.4865 26.6444 38.1067 22.9417C38.5772 20.0727 38.3562 17.1329 37.4619 14.3666C36.5676 11.6002 35.0259 9.0873 32.965 7.03668ZM34.81 22.4283C34.4306 24.7579 33.5061 26.9649 32.1119 28.8694C30.7176 30.7739 28.8932 32.322 26.7871 33.3876C24.6811 34.4533 22.353 35.0062 19.9928 35.0014C17.6325 34.9966 15.3067 34.4342 13.205 33.36C12.971 33.2395 12.7116 33.1766 12.4484 33.1767C12.3364 33.1767 12.2248 33.1879 12.115 33.21L6.51504 34.33C6.39846 34.3531 6.278 34.3471 6.1643 34.3125C6.0506 34.278 5.94716 34.2159 5.86314 34.1319C5.77911 34.0479 5.71707 33.9444 5.68252 33.8307C5.64797 33.717 5.64197 33.5966 5.66504 33.48L6.78504 27.8783C6.85911 27.5107 6.80623 27.1287 6.63504 26.795C5.56159 24.6929 4.9999 22.3669 4.99575 20.0065C4.99159 17.6461 5.54507 15.3182 6.6111 13.2123C7.67714 11.1064 9.22558 9.28205 11.1303 7.88797C13.035 6.49389 15.242 5.56947 17.5717 5.19001C19.922 4.80738 22.3296 4.98904 24.5959 5.72C26.8622 6.45097 28.9222 7.7103 30.606 9.39409C32.2898 11.0779 33.5491 13.1379 34.28 15.4042C35.011 17.6704 35.1927 20.078 34.81 22.4283Z" />
    //   <path d="M21.6667 25V18.3334C21.6667 17.8913 21.4912 17.4674 21.1786 17.1548C20.866 16.8423 20.4421 16.6667 20.0001 16.6667H18.3334C17.8914 16.6667 17.4675 16.8423 17.1549 17.1548C16.8423 17.4674 16.6667 17.8913 16.6667 18.3334C16.6667 18.7754 16.8423 19.1993 17.1549 19.5119C17.4675 19.8244 17.8914 20 18.3334 20V26.6667C18.3334 27.1087 18.509 27.5326 18.8216 27.8452C19.1341 28.1578 19.5581 28.3334 20.0001 28.3334H21.6667C22.1088 28.3334 22.5327 28.1578 22.8453 27.8452C23.1578 27.5326 23.3334 27.1087 23.3334 26.6667C23.3334 26.2247 23.1578 25.8007 22.8453 25.4882C22.5327 25.1756 22.1088 25 21.6667 25Z" />
    //   <path d="M20 15C20.9205 15 21.6667 14.2538 21.6667 13.3334C21.6667 12.4129 20.9205 11.6667 20 11.6667C19.0796 11.6667 18.3334 12.4129 18.3334 13.3334C18.3334 14.2538 19.0796 15 20 15Z" />
    // </svg>
  );
};

export default AboutIcon;
