import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <section dir={dir} className="privacy-policy  text--white  flex-fill">
      <h1 className="privacy-policy__main-title  text--green fw-bold fsize--3">
        {t('privacyPolicy.title')}
      </h1>
      <p className="fsize--6 mb-2">{t('privacyPolicy.lastUpdate')}</p>
      <p className="fsize--6 mb-2">{t('privacyPolicy.description')}</p>
      <h2 className="fsize--4 mt-4 mt-md-5 text--green  fw-bold ">
        {t('privacyPolicy.interpretationAndDefinitions.title')}
      </h2>
      <h3 className="fsize--5 mt-2 mt-md-3  fw-bold ">
        {t('privacyPolicy.interpretationAndDefinitions.interpretation.title')}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.interpretationAndDefinitions.interpretation.description'
        )}
      </p>
      <h3 className="fsize--5 mt-2 mt-md-3  fw-bold ">
        {t('privacyPolicy.interpretationAndDefinitions.definitions.title')}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.interpretationAndDefinitions.definitions.forThePurpose'
        )}
      </p>
      <ul className="privacyPolicy__list">
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.account'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.account'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.affiliate'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.affiliate'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.application'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.application'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.company'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.company'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.country'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.country'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.device'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.device'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.personalData'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.personalData'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.service'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.service'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.serviceProvider'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.serviceProvider'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.usageData'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.usageData'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.titles.you'
            )}{' '}
            {t(
              'privacyPolicy.interpretationAndDefinitions.definitions.descriptions.you'
            )}
          </p>
        </li>
      </ul>
      <h2 className="fsize--4 mt-4 mt-md-5 text--green  fw-bold ">
        {t('privacyPolicy.collectingAndUsingYourPersonalData.title')}
      </h2>
      <h3 className="fsize--5 mt-2 mt-md-3  fw-bold ">
        {t('privacyPolicy.collectingAndUsingYourPersonalData.types.title')}
      </h3>
      <h4 className="fsize--6 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.personal.title'
        )}
      </h4>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.personal.description'
        )}
      </p>
      <ul className="privacyPolicy__list">
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.types.personal.name'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.types.personal.phone'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.types.personal.usageData'
            )}
          </p>
        </li>
      </ul>
      <h4 className="fsize--6 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.usageData.title'
        )}
      </h4>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.usageData.points.1'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.usageData.points.2'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.usageData.points.3'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.types.usageData.points.4'
        )}
      </p>
      <h3 className="fsize--5 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.title'
        )}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.description'
        )}
      </p>
      <ul className="privacyPolicy__list">
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.1.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.1.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.2.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.2.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.3.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.3.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.4.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.4.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.5.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.5.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.6.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.6.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.7.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.7.description'
            )}
          </p>
        </li>
        <li>
          <p className="fsize--6 mb-2">
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.8.title'
            )}{' '}
            {t(
              'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.purposes.8.description'
            )}
          </p>
        </li>
      </ul>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.description'
        )}
      </p>
      <ul className="privacyPolicy__list">
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.1.title'
          )}{' '}
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.1.description'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.2.title'
          )}{' '}
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.2.description'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.3.title'
          )}{' '}
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.3.description'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.4.title'
          )}{' '}
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.4.description'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.5.title'
          )}{' '}
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.5.description'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.6.title'
          )}{' '}
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.useOfPersonalData.situations.6.description'
          )}
        </li>
      </ul>
      <h3 className="fsize--5 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.retentionOfPersonalData.title'
        )}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.retentionOfPersonalData.points.1'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.retentionOfPersonalData.points.2'
        )}
      </p>
      <h3 className="fsize--5 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.transferOfYourPersonalData.title'
        )}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.transferOfYourPersonalData.points.1'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.transferOfYourPersonalData.points.2'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.transferOfYourPersonalData.points.3'
        )}
      </p>
      <h3 className="fsize--5 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.deleteOfPersonalData.title'
        )}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.deleteOfPersonalData.points.1'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.deleteOfPersonalData.points.2'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.deleteOfPersonalData.points.3'
        )}
      </p>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.deleteOfPersonalData.points.4'
        )}
      </p>
      <h3 className="fsize--5 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.title'
        )}
      </h3>
      <h4 className="fsize--6 mt-2 mt-md-3  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.businessTransactions.title'
        )}
      </h4>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.businessTransactions.description'
        )}
      </p>
      <h4 className="fsize--6 mt-2 mt-md-3  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.lawEnforcement.title'
        )}
      </h4>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.lawEnforcement.description'
        )}
      </p>
      <h4 className="fsize--6 mt-2 mt-md-3  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.title'
        )}
      </h4>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.description'
        )}
      </p>
      <ul className="privacyPolicy__list">
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.points.1'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.points.2'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.points.3'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.points.4'
          )}
        </li>
        <li>
          {t(
            'privacyPolicy.collectingAndUsingYourPersonalData.disclosureOfPersonalData.otherLegalRequirements.points.5'
          )}
        </li>
      </ul>
      <h3 className="fsize--5 mt-2 mt-md-3 text--green  fw-bold ">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.securityOfPersonalData.title'
        )}
      </h3>
      <p className="fsize--6 mb-2">
        {t(
          'privacyPolicy.collectingAndUsingYourPersonalData.securityOfPersonalData.description'
        )}
      </p>
      <h2 className="fsize--4 mt-4 mt-md-5 text--green  fw-bold ">
        {t('privacyPolicy.childrenPrivacy.title')}
      </h2>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.childrenPrivacy.points.1')}
      </p>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.childrenPrivacy.points.2')}
      </p>
      <h2 className="fsize--4 mt-4 mt-md-5 text--green  fw-bold ">
        {t('privacyPolicy.linksToOtherWebsites.title')}
      </h2>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.linksToOtherWebsites.points.1')}
      </p>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.linksToOtherWebsites.points.2')}
      </p>
      <h2 className="fsize--4 mt-4 mt-md-5 text--green  fw-bold ">
        {t('privacyPolicy.changesToPrivacyPolicy.title')}
      </h2>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.changesToPrivacyPolicy.points.1')}
      </p>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.changesToPrivacyPolicy.points.2')}
      </p>
      <p className="fsize--6 mb-2">
        {t('privacyPolicy.changesToPrivacyPolicy.points.3')}
      </p>
      <h2 className="fsize--4 mt-4 mt-md-5 text--green  fw-bold ">
        {t('contactUs.title')}
      </h2>
      <p className="fsize--6 mb-2">{t('contactUs.description')}</p>
      <ul className="privacyPolicy__list">
        <li>{t('contactUs.points.1')}</li>
      </ul>
    </section>
  );
};

export default PrivacyPolicy;
