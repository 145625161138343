export const en = {
  language: 'عربي',
  navbar: {
    aboutUs: 'about us',
    home: 'home',
    terms: 'terms & conditions',
    privacyPolicy: 'privacy policy',
    contactUs: 'contact us',
  },
  home: {
    firstSection: {
      // title: "Welcome to Big Scrappers",
      title: {
        1: 'Welcome ',
        2: 'to Big Scrappers',
      },
      // paragraph: "Your Partner In Sustainable Aluminum Recycling",
      paragraph: 'Your partner in sustainable recycling for a greener future!',
    },
    secondSection: {
      paragraph1:
        'Welcome to Big Scrappers, your gateway to reducing waste and making a real impact in your community.',
      // 'Big Scrappers is your gateway to reducing waste and making a real impact. Join our community, be a change- maker, and transform the aluminum industry for a greener tomorrow.',
      paragraph2:
        'Join the movement! Sell your scrap to Big Scrappers, gain rewards and promote a circular economy for brighter and greener future',
      // 'Join Big Scrappers, the hub for eco-conscious individuals in the aluminum scrap and can industry. Connect, collaborate, and earn commissions as we revolutionize sustainability together.',
      visitWebsite: 'Visit Our Website',
    },
    thirdSection: {
      title: 'Big Scrappers App',
      getApplication: 'Get our mobile application',
    },
  },
  about: {
    about: 'about',
    scraption: 'Big Scrappers',
    firstSection: {
      1: 'Big Scrappers was founded in 2012 as a recycling company specializing in the collection of aluminum waste.Originally known as Kabnoury Recycling or Kabnoury Le Togaret El Khorda, the company recently transitioned to Big Scrappers, expanding its services to collect a broader range of recyclable materials.',
      2: 'Our core operation involves purchasing and collecting waste from individuals and companies to foster a circular economy while also incentivizing those who take part in our initiative with cash rewards or points that they can redeem from our marketplace.',
    },
    secondSection: {
      whatWeDo: 'What we do?',
      1: 'We are focused on acquiring a range of materials B2B and B2C such as metal, plastic, mixed plastic, cans, steel, car spare parts, electronics, stainless steel, red copper, yellow copper, tyres, cables, used oil, foil, car batteries, motorcycle batteries, hard plastic, cardboard, glass, aluminum waste and other materials. Through collecting and processing these materials in our trash yards, we are creating a circular economy that reduces waste, prolongs the lifespan of items and promotes sustainability in the communities where we operate in.',
    },
    mission: {
      mission: 'Our mission',
      section:
        'Our mission is to lead the way in sustainable waste management by transforming recyclable materials into valuable resources.',
    },
    vision: {
      vision: 'Our vision',
      section:
        'Our vision is to become a leader in recycling and sustainable waste management in the Middle East, recognized for our innovative approaches and unwavering commitment to environmental preservation',
    },
    joinSection: {
      joinUs: 'Join us at',
      description:
        'and be a part of the solution. Together, we can shape a greener future, where the value of aluminum is maximized, waste is minimized, and our planet thrives.',
    },
    ourValues: {
      title: 'Our core values are:',
      section1: {
        title: 'Environmental Stewardship',
        paragraph:
          'We are dedicated to protecting the environment by minimizing waste and promoting recycling, thereby ensuring a cleaner and healthier planet for future generations.',
      },
      section2: {
        title: 'Sustainability',
        paragraph:
          'Our commitment lies in fostering a circular economy by recycling a diverse array of materials, transforming waste into valuable resources.',
      },
      section3: {
        title: 'Innovation',
        paragraph:
          'From our inception as Kabnoury Recycling to our current identity as Big Scrappers, we consistently pursue innovative methods to enhance and expand our recycling processes and services.',
      },
      section4: {
        title: 'Customer Centricity',
        paragraph:
          'Our operations are designed to be both convenient and rewarding for our customers, facilitating an easy and beneficial recycling experience.',
      },
      section5: {
        title: 'Economic Growth',
        paragraph:
          'By purchasing waste materials from individuals and businesses, we create financial incentives that support local economies and promote sustainable practices.',
      },
    },

    videoSection: {
      description:
        "Is not just a platform; it's a movement. We believe that by harnessing the power of technology and collaboration, we can make a tangible difference in the world. Our mission is to create a global network of like-minded individuals who are dedicated to making sustainable choices and driving positive change.",
    },
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    lastUpdate: 'Last updated: March 24, 2024',
    description:
      'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    interpretationAndDefinitions: {
      title: 'Interpretation and Definitions',
      interpretation: {
        title: 'Interpretation',
        description:
          'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
        // paragraph: 'For the purposes of this Privacy Policy:',
        // 1: 'Account means a unique account created for You to access our Service or parts of our Service.',
        // 2: `Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`,
        // 3: `Application refers to Big Scrappers Merchant, the software program provided by the Company.`,
        // 4: `Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Big Scrappers , Zayed , Galleria 40 .`,
        // 5: `Country refers to: Egypt`,
      },
      definitions: {
        title: 'Definitions',
        forThePurpose: 'For the purposes of this Privacy Policy:',
        titles: {
          account: 'Account',
          affiliate: 'Affiliate',
          application: 'Application',
          company: 'Company',
          country: 'Country',
          device: 'Device',
          personalData: 'Personal Data',
          service: 'Service',
          serviceProvider: 'Service Provider',
          usageData: 'Usage Data',
          you: 'You',
        },
        descriptions: {
          account: `means a unique account created for You to access our Service or parts of our Service.`,
          affiliate: `means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`,
          application: `refers to Big Scrappers Merchant, the software program provided by the Company.`,
          company: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Big Scrappers , Zayed , Galleria 40 .`,
          country: `refers to: Egypt`,
          device: `means any device that can access the Service such as a computer, a cellphone or a digital tablet.`,
          personalData: `is any information that relates to an identified or identifiable individual.`,
          service: `refers to the Application.`,
          serviceProvider: `means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.`,
          usageData: `refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`,
          you: `means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`,
        },
      },
    },
    collectingAndUsingYourPersonalData: {
      title: 'Collecting and Using Your Personal Data',
      types: {
        title: 'Types of Data Collected',
        personal: {
          title: 'Personal Data',
          description:
            'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include,',
          name: 'First name and last name',
          phone: 'Phone number',
          usageData: 'Usage Data',
        },
        usageData: {
          title: 'Usage Data',
          points: {
            1: 'Usage Data is collected automatically when using the Service.',
            2: "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
            3: 'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
            4: 'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
          },
        },
      },
      useOfPersonalData: {
        title: 'Use of Your Personal Data',
        purposes: {
          description:
            'The Company may use Personal Data for the following purposes:',
          1: {
            title: 'To provide and maintain our Service,',
            description: ', including to monitor the usage of our Service.',
          },
          2: {
            title: 'To manage Your Account:',
            description:
              'to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
          },
          3: {
            title: 'For the performance of a contract:',
            description:
              'the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
          },
          4: {
            title: 'To contact You:',
            description:
              "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
          },
          5: {
            title: 'To provide You',
            description:
              'with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
          },
          6: {
            title: 'To manage Your requests:',
            description: 'To attend and manage Your requests to Us.',
          },
          7: {
            title: 'For business transfers:',
            description:
              'We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.',
          },
          8: {
            title: 'For other purposes:',
            description:
              'We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.',
          },
        },
        situations: {
          description:
            'We may share Your personal information in the following situations:',
          1: {
            title: 'With Service Providers:',
            description:
              'We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
          },
          2: {
            title: 'For business transfers:',
            description:
              'We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
          },
          3: {
            title: 'With Affiliates:',
            description:
              'We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
          },
          4: {
            title: 'With business partners:',
            description:
              'We may share Your information with Our business partners to offer You certain products, services or promotions.',
          },
          5: {
            title: 'With other users:',
            description:
              'when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.',
          },
          6: {
            title: 'With Your consent:',
            description:
              'We may disclose Your personal information for any other purpose with Your consent.',
          },
        },
      },
      retentionOfPersonalData: {
        title: 'Retention of Your Personal Data',
        points: {
          1: 'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
          2: 'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
        },
      },
      transferOfYourPersonalData: {
        title: 'Transfer of Your Personal Data',
        points: {
          1: "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
          2: 'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
          3: 'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
        },
      },
      deleteOfPersonalData: {
        title: 'Delete Your Personal Data',
        points: {
          1: 'You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.',
          2: 'Our Service may give You the ability to delete certain information about You from within the Service.',
          3: 'You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.',
          4: 'Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.',
        },
      },
      disclosureOfPersonalData: {
        title: 'Disclosure of Your Personal Data',
        businessTransactions: {
          title: 'Business Transactions',
          description:
            ' If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
        },
        lawEnforcement: {
          title: 'Law Enforcement',
          description:
            'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
        },
        otherLegalRequirements: {
          title: 'Other legal requirements',
          description:
            'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
          points: {
            1: 'Comply with a legal obligation',
            2: 'Protect and defend the rights or property of the Company',
            3: 'Prevent or investigate possible wrongdoing in connection with the Service',
            4: 'Protect the personal safety of Users of the Service or the public',
            5: 'Protect against legal liability',
          },
        },
      },
      securityOfPersonalData: {
        title: 'Security of Your Personal Data',
        description:
          'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
      },
    },
    childrenPrivacy: {
      title: "Children's Privacy",
      points: {
        1: 'Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.',
        2: "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
      },
    },
    linksToOtherWebsites: {
      title: 'Links to Other Websites',
      points: {
        1: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
        2: 'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
      },
    },
    changesToPrivacyPolicy: {
      title: 'Changes to this Privacy Policy',
      points: {
        1: 'We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
        2: `We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.`,
        3: 'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
      },
    },
    contactUs: {
      title: 'Contact Us',
      submit: 'Submit',
      fields: {
        name: 'Name',
        companyName: 'Company Name',
        mobile: 'Phone',
        inquirey: 'Inquiry',
        email: 'Email',
        message: 'Message',
      },
      placeHolders: {
        mobile: '(+20)',
        inquirey: 'Select Inquiry',
        message: 'Write your message..',
      },
      validation: {
        name: 'Please enter a valid name.',
        inquiry: 'Please select an inquiry type.',
        phone: 'Please, Enter phone number.',
        phoneNotValid: 'Please enter a valid mobile number.',
        email: 'Please enter a valid email address.',
        message: 'Please enter your message.',
      },
    },
  },
  page404: {
    heading: 'Something went wrong!',
    paragraph:
      "This page doesn't exist or was removed, we suggest you back to home..",
    link: 'Back To Home',
  },
  footer: {
    bigScrappersWebsite: 'Big Scrappers Website',
  },
  contactUs: {
    title: 'Contact Us',
    description:
      'If you have any questions regarding this privacy policy, you can contact us:',
    points: {
      1: 'Via phone number: +201100820963',
    },
    buyScrap: 'Buy Scrap',
    sellScrap: 'Sell Scrap',
  },
  // contactUs: {
  //   message: 'Message',
  //   enterMessage: 'Enter message',
  //   requiredMessage: 'Message is required',
  //   mobile: 'Mobile',
  //   enterMobile: 'Enter mobile',
  //   requiredMobile: 'Mobile is required',
  //   invalidMobile: 'Please, enter a valid mobile number',
  //   email: 'Email',
  //   enterEmail: 'Enter email',
  //   requiredEmail: 'Email is required',
  //   invalidEmail: 'Please, enter a valid email',
  //   send: 'Send',
  //   sentSuccessfully: 'Sent Successfully!',
  // },
  stores: {
    title: 'Stores',
    noActiveStores: 'Sorry, There are no active stores yet',
  },
  noData: 'No data',
  noInternet: {
    lostConnection: 'Connection Lost!',
    oops: `Sorry! It seems the internet connection has been lost.Please check your network connection and try again.`,
    tryAgain: 'Try Again',
  },
  supplyPrices: {
    title: 'Supply Prices',
    material: "Material",
    cashPrice: "Cash Price",
    points: "Points",
    note: "Prices are subject to change daily based on the current market rate",
  },
  inviteFriends: {
    heading: "Get points when registered to replace them from marketplace",
    enterPhone: "Enter your phone number to download Big Scrapper app and claim your referral",
    note: "This offer is exclusively available to new Scrappers. We collect your phone number for the purpose of awarding points to new Scrappers and we will not use it for any other reason.",
    claimOffer: "Claim offer",
    enterPhoneNumber: "Enter Phone Number",
  }
};
