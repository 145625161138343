import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const FirstSection = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.lang);

  return (
    <div
      dir="ltr"
      className={`home__first-section ${
        language === 'ar' ? 'home__first-section--rotate text-end' : ''
      }`}
    >
      <div className="home__first-section__text-holder d-flex flex-column align-items-md-center justify-content-center">
        <div
          className={`home__first-section__text-holder__holder d-flex flex-column ${
            language === 'ar' ? 'align-items-end' : ''
          }`}
        >
          <h1 className="text--white fw-bold mb-0">
            {t('home.firstSection.title.1')}
            <br />
            {t('home.firstSection.title.2')}
          </h1>
          <p
            dir={language === 'ar' ? 'rtl' : 'ltr'}
            className="fsize--5 text--green fw--500 mb-0"
          >
            {`${t('home.firstSection.paragraph')}`}
          </p>
          <Link
            to="/about-us"
            className="home__first-section__text-holder__about-link d-flex justify-content-center align-items-center text-capitalize text--white fw-bold fsize--4"
          >
            {t('navbar.aboutUs')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
