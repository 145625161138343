import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FirstSection = () => {
  const { t } = useTranslation();

  const { language } = useSelector((state) => state.lang);

  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <div className="about__first-section d-flex flex-column" dir={dir}>
      <h1 className="mb-0">
        <span className="d-block  text--white text-uppercase fw--700">
          {t('about.about')}
        </span>
        <span className="d-block text--green fw--500">
          {t('about.scraption')}
        </span>
      </h1>
      <p className="text--white mb-0 paragraph">{t('about.firstSection.1')}</p>
      <p className="text--white mb-0 paragraph">{t('about.firstSection.2')}</p>
    </div>
  );
};

export default FirstSection;
