import React from 'react';
import Location from '../../Assets/Icons/Location';

export default function StoreCard({ store }) {
  const { address, description, latitude, longitude, name } = store;
  return (
    <div className="store-card">
      <p className="name">{name}</p>
      <p className="description">{description}</p>
      <a
        href={`https://www.google.com/maps?q=${latitude},${longitude}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          <Location />
          <span>{address}</span>
        </p>
      </a>
    </div>
  );
}
