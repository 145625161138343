const PrivacyIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3594 30.6477C15.9625 30.6474 15.5724 30.542 15.2259 30.3416L10.7318 27.756C8.4979 26.4698 6.63655 24.5888 5.34035 22.3075C4.04416 20.0262 3.36024 17.4277 3.35938 14.7808V7.60659C3.35911 7.00933 3.5728 6.43319 3.95909 5.98969C4.34538 5.54618 4.87681 5.26684 5.45052 5.20573C7.2383 5.00947 8.99783 4.59768 10.6934 3.97871C12.2475 3.41006 13.7382 2.67049 15.14 1.77269C15.507 1.54055 15.9294 1.41772 16.3594 1.41772C16.7895 1.41772 17.2112 1.54055 17.5782 1.77269C18.9803 2.67036 20.4712 3.40992 22.0254 3.97871C23.7209 4.59769 25.4805 5.0095 27.2682 5.20577C27.8419 5.26689 28.3734 5.54622 28.7596 5.98971C29.1459 6.43321 29.3596 7.00934 29.3594 7.60659V14.7809C29.3585 17.4277 28.6746 20.0263 27.3784 22.3075C26.0822 24.5888 24.2208 26.4699 21.987 27.7561L17.4928 30.3417C17.1464 30.542 16.7562 30.6474 16.3594 30.6477ZM16.1921 3.53504C14.6681 4.50992 13.0477 5.31319 11.3587 5.93114C9.51643 6.60458 7.60428 7.05216 5.66139 7.26473C5.5788 7.27205 5.50189 7.31127 5.44594 7.37461C5.38999 7.43794 5.35909 7.52075 5.35938 7.60659V14.7809C5.36016 17.0583 5.94868 19.2942 7.06402 21.2571C8.17936 23.22 9.78098 24.8385 11.7031 25.9451L16.1973 28.5307C16.2467 28.5599 16.3025 28.5752 16.3594 28.5752C16.4162 28.5752 16.4721 28.5599 16.5215 28.5307L21.0156 25.9451C22.9378 24.8385 24.5394 23.22 25.6547 21.2571C26.7701 19.2942 27.3586 17.0583 27.3594 14.7809V7.60659C27.3597 7.52075 27.3287 7.43794 27.2728 7.37461C27.2168 7.31128 27.1399 7.27207 27.0573 7.26477C25.1144 7.05221 23.2023 6.60462 21.36 5.93118C19.671 5.31322 18.0506 4.50994 16.5266 3.53504C16.4762 3.50328 16.4183 3.48648 16.3593 3.48648C16.3003 3.48648 16.2425 3.50328 16.1921 3.53504Z"
        fill="white"
      />
      <path
        d="M19.6927 23.2503H13.026C12.2306 23.2494 11.4681 22.9218 10.9057 22.3393C10.3432 21.7569 10.0269 20.9672 10.026 20.1435V16.0012C10.0269 15.1775 10.3432 14.3878 10.9057 13.8054C11.4681 13.2229 12.2306 12.8953 13.026 12.8944H19.6927C20.4881 12.8953 21.2506 13.2229 21.8131 13.8054C22.3755 14.3878 22.6918 15.1775 22.6927 16.0012V20.1435C22.6918 20.9672 22.3755 21.7569 21.8131 22.3393C21.2506 22.9218 20.4881 23.2494 19.6927 23.2503ZM13.026 14.9656C12.7609 14.9659 12.5067 15.0751 12.3192 15.2692C12.1318 15.4634 12.0263 15.7266 12.026 16.0012V20.1435C12.0263 20.4181 12.1318 20.6813 12.3192 20.8755C12.5067 21.0696 12.7609 21.1788 13.026 21.1791H19.6927C19.9578 21.1788 20.212 21.0696 20.3995 20.8755C20.587 20.6813 20.6924 20.4181 20.6927 20.1435V16.0012C20.6924 15.7266 20.587 15.4634 20.3995 15.2692C20.212 15.0751 19.9578 14.9659 19.6927 14.9656H13.026Z"
        fill="white"
      />
      <path
        d="M19.0261 14.9656H13.6927C13.4275 14.9656 13.1732 14.8564 12.9857 14.6622C12.7982 14.468 12.6928 14.2047 12.6927 13.9301V11.1685C12.6927 10.1614 13.079 9.1956 13.7667 8.4835C14.4543 7.77139 15.3869 7.37134 16.3594 7.37134C17.3318 7.37134 18.2645 7.77139 18.9521 8.4835C19.6397 9.1956 20.0261 10.1614 20.0261 11.1685V13.9301C20.026 14.2047 19.9206 14.468 19.7331 14.6622C19.5456 14.8564 19.2912 14.9656 19.0261 14.9656ZM14.6927 12.8945H18.0261V11.1685C18.0261 10.7107 17.8505 10.2717 17.5379 9.94804C17.2253 9.62436 16.8014 9.44251 16.3594 9.44251C15.9174 9.44251 15.4934 9.62436 15.1809 9.94804C14.8683 10.2717 14.6927 10.7107 14.6927 11.1685V12.8945Z"
        fill="white"
      />
    </svg>

    // <svg
    //   width="33"
    //   height="33"
    //   viewBox="0 0 33 33"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M16.3594 30.6432C15.9625 30.6429 15.5724 30.5412 15.2259 30.3477L10.7318 27.8509C8.4979 26.6089 6.63655 24.7925 5.34035 22.5896C4.04416 20.3868 3.36024 17.8775 3.35938 15.3216V8.39389C3.35911 7.81715 3.5728 7.26081 3.95909 6.83255C4.34538 6.40428 4.87681 6.13454 5.45052 6.07553C7.2383 5.88602 8.99783 5.48837 10.6934 4.89068C12.2475 4.34156 13.7382 3.62741 15.14 2.76046C15.507 2.5363 15.9294 2.41769 16.3594 2.41769C16.7895 2.41769 17.2112 2.5363 17.5782 2.76046C18.9803 3.62728 20.4712 4.34143 22.0254 4.89068C23.7209 5.48838 25.4804 5.88604 27.2682 6.07557C27.8419 6.13458 28.3734 6.40432 28.7596 6.83257C29.1459 7.26083 29.3596 7.81716 29.3594 8.39389V15.3216C29.3585 17.8775 28.6746 20.3868 27.3784 22.5897C26.0822 24.7925 24.2208 26.609 21.987 27.851L17.4928 30.3477C17.1464 30.5412 16.7562 30.6429 16.3594 30.6432ZM16.1921 4.46225C14.6681 5.40363 13.0477 6.1793 11.3587 6.77601C9.51643 7.42631 7.60428 7.85851 5.66139 8.06377C5.5788 8.07084 5.50189 8.10871 5.44594 8.16987C5.38999 8.23103 5.35909 8.311 5.35938 8.39389V15.3216C5.36016 17.5208 5.94868 19.6799 7.06402 21.5753C8.17936 23.4707 9.78098 25.0336 11.7031 26.1022L16.1973 28.599C16.2467 28.6271 16.3025 28.6419 16.3594 28.6419C16.4162 28.6419 16.4721 28.6271 16.5215 28.599L21.0156 26.1022C22.9378 25.0336 24.5394 23.4707 25.6547 21.5753C26.7701 19.6799 27.3586 17.5208 27.3594 15.3216V8.39389C27.3597 8.311 27.3287 8.23103 27.2728 8.16988C27.2168 8.10873 27.1399 8.07086 27.0573 8.06381C25.1144 7.85855 23.2023 7.42635 21.36 6.77605C19.671 6.17932 18.0506 5.40364 16.5266 4.46225C16.4762 4.43158 16.4183 4.41536 16.3593 4.41536C16.3003 4.41536 16.2425 4.43158 16.1921 4.46225Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M19.6927 23.5H13.026C12.2306 23.4991 11.4681 23.1828 10.9056 22.6204C10.3432 22.0579 10.0269 21.2954 10.026 20.5V16.5C10.0269 15.7046 10.3432 14.9421 10.9056 14.3796C11.4681 13.8172 12.2306 13.5009 13.026 13.5H19.6927C20.4881 13.5009 21.2506 13.8172 21.813 14.3796C22.3755 14.9421 22.6918 15.7046 22.6927 16.5V20.5C22.6918 21.2954 22.3755 22.0579 21.813 22.6204C21.2506 23.1828 20.4881 23.4991 19.6927 23.5ZM13.026 15.5C12.7609 15.5003 12.5067 15.6057 12.3192 15.7932C12.1317 15.9807 12.0263 16.2349 12.026 16.5V20.5C12.0263 20.7651 12.1317 21.0193 12.3192 21.2068C12.5067 21.3943 12.7609 21.4997 13.026 21.5H19.6927C19.9578 21.4997 20.212 21.3943 20.3995 21.2068C20.5869 21.0193 20.6924 20.7651 20.6927 20.5V16.5C20.6924 16.2349 20.5869 15.9807 20.3995 15.7932C20.212 15.6057 19.9578 15.5003 19.6927 15.5H13.026Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M19.0261 15.5H13.6927C13.4276 15.4999 13.1732 15.3946 12.9857 15.207C12.7982 15.0195 12.6928 14.7652 12.6927 14.5V11.8334C12.6927 10.8609 13.0791 9.92826 13.7667 9.24063C14.4543 8.553 15.387 8.16669 16.3594 8.16669C17.3319 8.16669 18.2645 8.553 18.9521 9.24063C19.6398 9.92826 20.0261 10.8609 20.0261 11.8334V14.5C20.026 14.7652 19.9206 15.0195 19.7331 15.207C19.5456 15.3946 19.2913 15.4999 19.0261 15.5ZM14.6927 13.5H18.0261V11.8334C18.0261 11.3913 17.8505 10.9674 17.5379 10.6548C17.2254 10.3423 16.8014 10.1667 16.3594 10.1667C15.9174 10.1667 15.4935 10.3423 15.1809 10.6548C14.8683 10.9674 14.6927 11.3913 14.6927 11.8334V13.5Z"
    //     fill="white"
    //   />
    // </svg>
  );
};

export default PrivacyIcon;
