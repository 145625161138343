import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../Store/Language';
import { CheckOutlined } from '@ant-design/icons';

// components
import LanguageIcon from './LanguageIcon';
import { useState } from 'react';

const LanguageBtn = ({ navBtn }) => {
  const [toggle, setToggle] = useState(false);

  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const dispatch = useDispatch();

  const handleLanguageChange = (value) => {
    dispatch(setLanguage(value));
  };

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'عربي', value: 'ar' },
    { label: 'Bosnian', value: 'hr' },
  ];

  return (
    <button
      onClick={() => setToggle((pre) => !pre)}
      type="button"
      className="language-btn language-btn--nav"
    >
      <LanguageIcon />
      <p
        style={{
          color: '#fff',
          fontsize: '16px',
          lineHeight: '21px',
          fontWeight: '500',
        }}
      >
        {language === 'ar'
          ? 'عربي'
          : language === 'hr'
          ? 'BS'
          : language.toUpperCase()}
      </p>
      {toggle && (
        <ul className="language-list" dir={dir}>
          {languages.map((lang) => (
            <li
              onClick={() => handleLanguageChange(lang.value)}
              key={lang.value}
              style={{
                color: language === lang.value ? '#60EC2F' : '#000',
              }}
            >
              <span>{lang.label}</span>
              <span>{language === lang.value && <CheckOutlined />}</span>
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};

export default LanguageBtn;
