import React from 'react';

export default function Location() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.01675 7.57533C4.65842 0.358661 15.3501 0.366994 16.9834 7.58366C17.9417 11.817 15.3084 15.4003 13.0001 17.617C12.1935 18.3949 11.1165 18.8297 9.99592 18.8297C8.87529 18.8297 7.79835 18.3949 6.99175 17.617C4.69175 15.4003 2.05842 11.8087 3.01675 7.57533Z"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M12.5999 9.09121C12.5999 9.60544 12.4474 10.1081 12.1617 10.5357C11.876 10.9633 11.47 11.2965 10.9949 11.4933C10.5198 11.6901 9.99702 11.7416 9.49267 11.6413C8.98832 11.5409 8.52504 11.2933 8.16143 10.9297C7.79781 10.5661 7.55018 10.1028 7.44986 9.59845C7.34954 9.0941 7.40103 8.57132 7.59782 8.09623C7.7946 7.62115 8.12785 7.21508 8.55542 6.92939C8.98299 6.6437 9.48567 6.49121 9.9999 6.49121C10.6895 6.49121 11.3508 6.76514 11.8384 7.25273C12.326 7.74033 12.5999 8.40165 12.5999 9.09121V9.09121Z"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
}
