import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CustomOutlinedInput = ({
  label,
  error,
  onCodeChange,
  inquiryType,
  ...props
}) => {
  const { t } = useTranslation();
  // const [inquiryType, setInquiryType] = useState('');

  const { language } = useSelector((state) => state.lang);

  const hasError = Boolean(error); // Only change color if there is a real error

  const styles = {
    base: {
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: hasError ? '2px solid red' : '2px solid #8D8D8D',
      borderRadius: '0px',
      outline: 'none',
      color: '#fff',
    },
    hoverFocus: {
      onMouseEnter: (e) => {
        if (!hasError) e.target.style.borderBottom = '2px solid #60EC2F';
      },
      onMouseLeave: (e) => {
        if (!hasError) e.target.style.borderBottom = '2px solid #8D8D8D';
      },
      onFocus: (e) => (e.target.style.borderBottom = '2px solid #60EC2F'),
      onBlur: (e) =>
        (e.target.style.borderBottom = hasError
          ? '2px solid red'
          : '2px solid #8D8D8D'),
    },
  };

  // const inquiryType = localStorage.getItem('inquiryType');
  let inputType;

  if (props.component === 'textarea') {
    inputType = (
      <textarea
        rows={4}
        id={label}
        {...props}
        style={styles.base}
        {...styles.hoverFocus}
      />
    );
  } else if (props.component === 'select') {
    inputType = (
      <select
        id={label}
        {...props}
        style={{
          ...styles.base,
          color:
            inquiryType ===
              t('privacyPolicy.contactUs.placeHolders.inquirey') || !inquiryType
              ? '#8d8d8d'
              : '#fff',
        }}
        {...styles.hoverFocus}
        className="select-input"
      >
        <option selected hidden>
          {t('privacyPolicy.contactUs.placeHolders.inquirey')}
        </option>

        <option value={t('contactUs.buyScrap')}>
          {t('contactUs.buyScrap')}
        </option>
        <option value={t('contactUs.sellScrap')}>
          {t('contactUs.sellScrap')}
        </option>
      </select>
    );
  } else if (props.component === 'phone') {
    inputType = (
      <div className="phone-input">
        <select onChange={onCodeChange}>
          <option value="+20">(+20)</option>
          <option value="+387">(+387)</option>
        </select>
        <input
          {...props}
          id={label}
          style={styles.base}
          {...styles.hoverFocus}
        ></input>
      </div>
    );
  } else {
    inputType = (
      <>
        <input
          {...props}
          id={label}
          style={styles.base}
          {...styles.hoverFocus}
        />
      </>
    );
  }

  return (
    <div style={{ marginBottom: '16px' }} className="custom-outlined-input">
      <label
        htmlFor={label}
        style={{ display: 'block', marginBottom: '8px', color: '#fff' }}
      >
        {label}
      </label>
      {inputType}
      {error && (
        <p
          className="error-msg"
          // style={{ color: 'red', fontSize: '14px', marignTop: '1rem' }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default CustomOutlinedInput;
