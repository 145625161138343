import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function MissionAndVision() {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.lang);
  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <div className="about__mission-and-vision" dir={dir}>
      <div className="d-flex flex-column">
        <h2 className="d-block  text--yellow fw--500">
          {t('about.mission.mission')}
        </h2>
        <p className="text--white fw--400 mb-0">{t('about.mission.section')}</p>
      </div>
      <div className="d-flex flex-column">
        <h2 className="d-block  text--yellow fw--500">
          {t('about.vision.vision')}
        </h2>
        <p className="text--white fw--400 mb-0">{t('about.vision.section')}</p>
      </div>
    </div>
  );
}
