import { useTranslation } from 'react-i18next';

// assets
import vector1 from '../../Assets/Images/Home/second-section__vector--1.png';
import vector2 from '../../Assets/Images/Home/second-section__vector--2.png';
import { useSelector } from 'react-redux';

const SecondSection = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.lang);

  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <div dir={dir} className="home__second-section">
      <div className="home__second-section__cols row mx-0 mt-0">
        <div className="col-xl-6 px-0 px-md-3">
          <div className="home__second-section__col d-flex flex-column align-items-center">
            <img src={vector1} alt="" className="d-block" />
            <p className="text--white text-center fw--700 fsize--6 mb-0">
              {t('home.secondSection.paragraph1')}
            </p>
          </div>
        </div>
        <div className="col-xl-6 px-0 px-md-3">
          <div className="home__second-section__col d-flex flex-column align-items-center">
            <img src={vector2} alt="" className="d-block" />
            <p className="text--white text-center fw--700 fsize--6 mb-0">
              {t('home.secondSection.paragraph2')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
