const LanguageIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8594 0.5C8.04944 0.5 0.859375 7.69063 0.859375 16.5C0.859375 25.3099 8.05 32.5 16.8594 32.5C25.6693 32.5 32.8594 25.3094 32.8594 16.5C32.8594 7.69006 25.6688 0.5 16.8594 0.5ZM12.1127 3.19644C11.0183 4.59481 10.2284 6.32475 9.683 8H5.58575C7.23256 5.82119 9.49531 4.13313 12.1127 3.19644ZM4.38769 9.87438H9.15987C8.74213 11.6507 8.49556 13.5738 8.43638 15.5625H2.76625C2.90088 13.5183 3.47206 11.5917 4.38769 9.87438ZM2.76625 17.4375H8.43638C8.49481 19.403 8.73656 21.3041 9.1455 23.0625H4.35462C3.45837 21.3616 2.89925 19.4569 2.76625 17.4375ZM5.53806 24.9375H9.66263C10.2106 26.6379 11.0076 28.3914 12.1127 29.8036C9.47019 28.8579 7.18875 27.1467 5.53806 24.9375ZM15.9219 30.4646C13.8206 29.7524 12.3909 27.029 11.6417 24.9375H15.9219V30.4646ZM15.9219 23.0625H11.0729C10.6343 21.3287 10.3744 19.4226 10.3121 17.4375H15.9219V23.0625ZM15.9219 15.5625H10.3121C10.3752 13.5534 10.6407 11.6255 11.0891 9.875H15.9219V15.5625ZM15.9219 8H11.6641C12.4141 5.92987 13.8372 3.24194 15.9219 2.53538V8ZM28.133 8H24.0357C23.4904 6.32512 22.7006 4.59506 21.6061 3.19644C24.2234 4.13313 26.4862 5.82119 28.133 8ZM17.7969 2.53538C19.8818 3.24206 21.3049 5.93038 22.0546 8H17.7969V2.53538ZM17.7969 9.875H22.6297C23.0779 11.6255 23.3436 13.5534 23.4067 15.5625H17.7969V9.875ZM17.7969 17.4369H23.4067C23.3443 19.4226 23.0844 21.3287 22.6458 23.0625H17.7969V17.4369ZM17.7969 30.4646V24.9369H22.0771C21.3284 27.0278 19.8987 29.7522 17.7969 30.4646ZM21.6061 29.8036C22.7113 28.3913 23.5083 26.6376 24.0561 24.9369H28.1807C26.53 27.1467 24.2486 28.8579 21.6061 29.8036ZM29.3641 23.0625H24.5733C24.9823 21.3041 25.224 19.403 25.2824 17.4369H30.9526C30.8195 19.4569 30.2604 21.3616 29.3641 23.0625ZM30.9525 15.5625H25.2824C25.2232 13.5738 24.9766 11.6507 24.5589 9.875H29.3311C30.2467 11.5917 30.8179 13.5183 30.9525 15.5625Z"
        fill="white"
      />
    </svg>

    // <svg
    //   width="40"
    //   height="40"
    //   viewBox="0 0 41 40"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="language-btn__icon"
    // >
    //   <g clipPath="url(#clip0_491_76)">
    //     <path d="M20.5 0C9.48758 0 0.5 8.98828 0.5 20C0.5 31.0124 9.48828 40 20.5 40C31.5124 40 40.5 31.0117 40.5 20C40.5 8.98758 31.5117 0 20.5 0ZM14.5666 3.37055C13.1987 5.11852 12.2113 7.28094 11.5295 9.375H6.40797C8.46648 6.65148 11.2949 4.54141 14.5666 3.37055ZM4.91039 11.718H10.8756C10.3534 13.9384 10.0452 16.3423 9.97125 18.8281H2.88359C3.05188 16.2729 3.76586 13.8646 4.91039 11.718ZM2.88359 21.1719H9.97125C10.0443 23.6287 10.3465 26.0052 10.8577 28.2031H4.86906C3.74875 26.077 3.04984 23.6962 2.88359 21.1719ZM6.34836 30.5469H11.5041C12.189 32.6723 13.1853 34.8643 14.5666 36.6295C11.2635 35.4473 8.41172 33.3084 6.34836 30.5469ZM19.3281 37.4558C16.7016 36.5655 14.9144 33.1613 13.9779 30.5469H19.3281V37.4558ZM19.3281 28.2031H13.267C12.7187 26.0359 12.3938 23.6533 12.3159 21.1719H19.3281V28.2031ZM19.3281 18.8281H12.3159C12.3948 16.3168 12.7267 13.9069 13.2871 11.7188H19.3281V18.8281ZM19.3281 9.375H14.0059C14.9434 6.78734 16.7223 3.42742 19.3281 2.54422V9.375ZM34.592 9.375H29.4705C28.7888 7.28141 27.8015 5.11883 26.4334 3.37055C29.7051 4.54141 32.5335 6.65148 34.592 9.375ZM21.6719 2.54422C24.278 3.42758 26.0569 6.78797 26.9941 9.375H21.6719V2.54422ZM21.6719 11.7188H27.7129C28.2732 13.9069 28.6052 16.3168 28.6841 18.8281H21.6719V11.7188ZM21.6719 21.1711H28.6841C28.6062 23.6533 28.2813 26.0359 27.733 28.2031H21.6719V21.1711ZM21.6719 37.4558V30.5461H27.0221C26.0863 33.1597 24.2991 36.5653 21.6719 37.4558ZM26.4334 36.6295C27.8148 34.8641 28.8111 32.672 29.4959 30.5461H34.6516C32.5883 33.3084 29.7365 35.4473 26.4334 36.6295ZM36.1309 28.2031H30.1424C30.6536 26.0052 30.9558 23.6287 31.0288 21.1711H38.1165C37.9502 23.6962 37.2512 26.077 36.1309 28.2031ZM38.1164 18.8281H31.0288C30.9548 16.3423 30.6466 13.9384 30.1244 11.7188H36.0896C37.2341 13.8646 37.9481 16.2729 38.1164 18.8281Z" />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_491_76">
    //       <rect width="40" height="40" transform="translate(0.5)" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};

export default LanguageIcon;
