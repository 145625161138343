export const allowNumbersOnly = (e, allowFloat = false) => {
    const isCopyingOrPasting = e.ctrlKey && /^[cva]$/i.test(e.key)
    if (
        e.key === "Enter" ||
        e.key === "Backspace" ||
        (allowFloat && e.key === ".") ||
        e.key.startsWith("ArrowLeft") ||
        e.key.startsWith("ArrowRight") ||
        isCopyingOrPasting
    ) {
        return;
    }
    if (/\D+/gi.test(e.key)) {
        e.preventDefault();
    }
};

export const getFullMobileNumber = (mobile, countryCode) => {
    return `${countryCode === "20"
        ? `2${mobile.length < 11 ? "0" : ""
        }`
        : `387${mobile.length < 9 ? "0" : ""
        }`
        }${mobile}`
}