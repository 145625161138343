import { useTranslation } from 'react-i18next';
import { noInternet } from '../Assets/Images/noInternet';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useState } from 'react';
import Wrapper from '../Components/Wrapper/Wrapper';

export default function NoInternet() {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const language = useSelector((state) => state.lang.language);
  const dir = i18next.dir(language);
  const navigate = useNavigate();

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (navigator.onLine) {
        navigate(-1, { replace: true });
      }
    }, 2000);
  };
  return (
    <Wrapper>
      <div className="no-internet" dir={dir}>
        <img src={noInternet} alt="no internet" />
        <div className="text-container">
          <h1>{t('noInternet.lostConnection')}</h1>
          <p>{t('noInternet.oops')}</p>
        </div>
        <Button loading={loading} onClick={handleClick}>
          {t('noInternet.tryAgain')}
        </Button>
      </div>
    </Wrapper>
  );
}
