import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import ValueCard from './ValueCard';

export default function OurCore() {
  const { t } = useTranslation();

  const list = [
    {
      id: 1,
      title: t('about.ourValues.section1.title'),
      description: t('about.ourValues.section1.paragraph'),
    },
    {
      id: 2,
      title: t('about.ourValues.section2.title'),
      description: t('about.ourValues.section2.paragraph'),
    },
    {
      id: 3,
      title: t('about.ourValues.section3.title'),
      description: t('about.ourValues.section3.paragraph'),
    },
    {
      id: 4,
      title: t('about.ourValues.section4.title'),
      description: t('about.ourValues.section4.paragraph'),
    },
    {
      id: 5,
      title: t('about.ourValues.section5.title'),
      description: t('about.ourValues.section5.paragraph'),
    },
  ];

  return (
    <div className="about__our-core">
      <h2 className="text--white">{t('about.ourValues.title')}</h2>
      <ul className="about__our-core__list">
        {list.map((ele) => (
          <ValueCard
            key={ele.id}
            title={ele.title}
            description={ele.description}
          />
        ))}
      </ul>
    </div>
  );
}
