import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openTerms: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openTermsModal: (state) => {
      state.openTerms = true;
    },
    closeTermsModal: (state) => {
      state.openTerms = false;
    },
  },
});

const { reducer, actions } = uiSlice;
export const { openTermsModal, closeTermsModal } = actions;
export default reducer;
