import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from './Localization/i18next';
import persistStore from 'redux-persist/es/persistStore';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

// sass
import './Assets/Styles/style.scss';

// components
import store from './Store/index';
import App from './App';
import TransitionToTop from './Components/TransitionToTop';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <TransitionToTop />
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
