import { getResource, getResourceBs, postResource } from './Services';
import store from '../Store/index';

export function getCurrentLang() {
  const state = store.getState();
  const { language } = state.lang;
  return language;
}

export function getTermsAndConditions(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/User/GetTermsAndConditions?forScarption=true&language=${lang}`;
  getResource(path, onSuccess, onFail);
}

export function contactUs(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/ContactUs?language=${lang}`;
  postResource(path, data, onSuccess, onFail);
}

export function getStores(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/Admin/GetActiveStores?language=${lang}`;

  if (lang === 'hr') {
    getResourceBs(path, onSuccess, onFail);
  } else {
    getResource(path, onSuccess, onFail);
  }
}

export function getSupplyTypes(onSuccess, onFail) {
  const lang = getCurrentLang();
  const path = `api/ScrapperSupplyType/ForUser?language=${lang}`;
  if (lang === 'hr') {
    getResourceBs(path, onSuccess, onFail);
  } else {
    getResource(path, onSuccess, onFail);
  }
}

export function claimInviteFriendOffer(data, onSuccess, onFail) {
  const lang = getCurrentLang();
  const { payload, region } = data;
  const path = `api/InviteFriend?language=${lang}`;
  postResource(path, payload, onSuccess, onFail, region);
}