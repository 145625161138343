import { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import parseMax, { isValidPhoneNumber } from 'libphonenumber-js/max';
import { allowNumbersOnly } from '../../Helpers/HelpfulFunction';

// assets
import egyptFlag from '../../Assets/Icons/egypt-flag.svg';
import bosnaFlag from '../../Assets/Icons/bosna-flag.svg';
import selectArrow from '../../Assets/Icons/select-arrow.svg';

const PhoneInput = ({ form }) => {
  const [selectedCode, setSelectedCode] = useState('20');
  const { language } = useSelector((state) => state.lang);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const coutriesCodes = [
    {
      id: 1,
      value: '20',
      country: 'Egypt',
      icon: egyptFlag,
      label: (
        <div className="invite-friends__holder__form__phone-input-holder__country-select__selected d-flex align-items-center justify-content-md-center">
          <img src={egyptFlag} alt="" className="img-fluid me-1" />
          <span className="fsize--6 fw-bold">+20</span>
        </div>
      ),
    },
    {
      id: 2,
      value: '387',
      country: 'Bosna',
      icon: bosnaFlag,
      label: (
        <div className="invite-friends__holder__form__phone-input-holder__country-select__selected d-flex align-items-center justify-content-md-center">
          <img src={bosnaFlag} alt="" className="img-fluid me-1" />
          <span className="fsize--6 fw-bold">+387</span>
        </div>
      ),
    },
  ];

  const handlePhoneValidition = (_, value) => {
    const country = selectedCode === '20' ? 'EG' : 'BA';
    const maxAcceptedLength = selectedCode === '20' ? 11 : 8;
    if (!value) {
      return Promise.reject(new Error(''));
    } else if (
      !/^\d+$/.test(value) ||
      !isValidPhoneNumber(value, country) ||
      value.length > maxAcceptedLength ||
      (country === 'BA' && !value.startsWith('6'))
    ) {
      return Promise.reject(new Error(''));
    } else if (parseMax(value, country).getType() !== 'MOBILE') {
      return Promise.reject(new Error());
    }
    return Promise.resolve();
  };

  // const handleCountryChange = (value) => {
  //   setSelectedCode(value);
  // };

  useEffect(() => {
    if (form?.getFieldValue('mobile')) {
      form.validateFields(['mobile']);
    }
  }, [selectedCode, form]);

  useEffect(() => {
    if (searchParams) {
      const region = searchParams?.get('region');
      setSelectedCode(region === 'eg' ? '20' : '387');
    }
  }, [searchParams]);

  return (
    <div dir="ltr" className="invite-friends__holder__form__phone-input-holder">
      <div className="row m-0">
        <div className="col-4 ps-0 pe-md-4">
          <Form.Item name="countryCode" className="mb-0">
            <Select
              name="countryCode"
              className="invite-friends__holder__form__phone-input-holder__country-select"
              placeholder=""
              options={coutriesCodes}
              popupMatchSelectWidth={false}
              popupClassName="invite-friends__holder__form__phone-input-holder__country-select__dropdown"
              suffixIcon={
                <img src={selectArrow} alt="" className="d-block img-fluid" />
              }
              disabled
              // onChange={handleCountryChange}
              // optionRender={(option) => (
              //   <div
              //     key={option.data.id}
              //     className="invite-friends__holder__form__phone-input-holder__country-select__option d-flex align-items-center gap-2"
              //   >
              //     <img
              //       src={option.data.icon}
              //       alt={`${option.data.country}`}
              //       className="d-block img-fluid"
              //     />
              //     <span className="fsize--11 font--bold text--dark-blue">
              //       +{option.data.value}
              //     </span>
              //   </div>
              // )}
            />
          </Form.Item>
        </div>
        <div className="col-8 pe-0">
          <Form.Item
            name="mobile"
            className="mb-0"
            rules={[
              {
                validator: handlePhoneValidition,
              },
            ]}
          >
            <Input
              placeholder={t('inviteFriends.enterPhoneNumber')}
              className="invite-friends__holder__form__phone-input-holder__input fsize--6 text--dark-blue"
              onKeyDown={allowNumbersOnly}
              data-lang={language}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;
