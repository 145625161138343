// components
import FirstSection from '../Components/AboutUs/FirstSection';
import SecondSection from '../Components/AboutUs/SecondSection';
import ThirdSection from '../Components/AboutUs/ThirdSection';
import FourthSection from '../Components/AboutUs/FourthSection';
import MissionAndVision from '../Components/AboutUs/MissionAndVision';
import OurCore from '../Components/AboutUs/OurCore';
import { useSelector } from 'react-redux';

const AboutUs = () => {
  const { language } = useSelector((state) => state.lang);

  const dir = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <section className="about flex-fill" dir={dir}>
      <FirstSection />
      <SecondSection />
      <MissionAndVision />
      <ThirdSection />
      <OurCore />
      <FourthSection />
    </section>
  );
};

export default AboutUs;
